<template>
	<div style='padding: 24rem;'>
		<div class='div-btn' style='display: flex;justify-content: space-between;'>
			<div>
				<div style='margin-bottom: 12rem'>行政班</div>
				<el-tree
						:data='dataArr'
						:props='defaultProps'
						accordion
						node-key='id'
						show-checkbox
						@check='onCheck'>
				</el-tree>
			</div>
			<div>
				<div style='margin-bottom: 12rem'>教学班</div>
				<el-tree
						:data='dataArrTo'
						:props='defaultProps'
						accordion
						node-key='id'
						show-checkbox
						@check='onCheckTo'>
				</el-tree>
			</div>
			<div></div>
		</div>
		<el-button size='small' style='height: 32rem;width: 80rem;margin-bottom: 24rem' type='primary' @click='createTextStyle'>
			保存
		</el-button>
		<div style='width: calc(100%);height: 1rem;background: #efefef;margin-bottom: 24rem'></div>


		<div v-if='false' style='display: flex'>
			<el-button size='small' style='height: 32rem;margin-right: 24rem' @click='onDownload'>下载模板</el-button>

			<div>
				<el-upload
						:action="$_axios2.defaults.baseURL + '/api/tool/import/auth-binding'"
						:before-upload='beforeUpload'
						:disabled='disabled'
						:headers="{ authorization: 'Bearer ' + $store.getters.token }"
						:on-success='onSuccess'
						:show-file-list='false'
						accept='.xlsx,.xls'
						class='upload-demo'
				>
					<el-button :disabled='disabled' size='small' type='primary'>点击上传</el-button>
				</el-upload>
				<div v-if='disabled' style='font-size: 12rem;color: red;margin-top: 4rem'>*请先选择分配的人员的菜单权限</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	_config:{"route":{"path":"list","meta":{"title":"列表"}}},
  data() {
		return {
			data: {
				admin_menu: [],
				teaching_menu: [],
			},
			dataArr: [],
			dataArrTo: [],
			defaultProps: {
				children: 'child',
				label: 'name',
			},
			disabled: true,
		};
	},

	methods: {
		onCheck(checkedNodes, data) {
			this.data.admin_menu = data.checkedKeys.concat(data.halfCheckedKeys);
		},

		onCheckTo(checkedNodes, data) {
			this.data.teaching_menu = data.checkedKeys.concat(data.halfCheckedKeys);
		},

		onSuccess(response) {
			if (response.status === 0) {
				this.$message.success(response.msg);
			} else this.$message.error(response.msg);
		},

		beforeUpload(file) {
			const testmsg = file.name.substring(file.name.lastIndexOf('.') + 1);
			const extension = testmsg === 'xls';
			const extension2 = testmsg === 'xlsx';
			if (!extension && !extension2) {
				this.$message({
					message: '上传文件只能是xls、xlsx格式!',
					type: 'warning',
				});
			}
			return extension || extension2;
		},
		createTextStyle() {
			this.$_axios2.post('api/tool/import/power', {
				...this.data,
			}).then((res) => {
				if (res.data.status === 0) {
					this.$message.success(res.data.msg);
					this.disabled = false;
				} else {
					this.$message.error(res.data.msg);
				}
			});
		},
		onDownload() {
			location.href = process.env.VUE_APP_URL_ONE + '/menu_power.xlsx';
		},
	},
	created() {
		this.$_axios.get('site/menu?type=2').then(res => {
			this.dataArr = res.data.data;
			this.dataArrTo = res.data.data;
		});
	},
};
</script>

<style lang='scss' scoped>
.div-btn {
	margin-bottom: 24rem;
	}
</style>
